var slidesContainer = $('.slides-container');
var leftArrow = slidesContainer.first().data('leftarrow');
var rightArrow = slidesContainer.first().data('rightarrow');
$('.slides-container').slick({
  dots: true,
  arrows: false,
  mobileFirst: true,
  customPaging: function() {
    return '<i class="fa fa-circle-o"></i><i class="fa active-dot fa-circle"></i>';
  },
  responsive: [
    {
      breakpoint: (breakpoints.md-1),
      settings: {
        dots: false,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: leftArrow,
        nextArrow: rightArrow
      }
    }
  ]
});