$(window).on('load', function(){

  if ($('#google-map').length > 0) {
    $.ajax({
      method: 'POST',
      url: ajaxDir+'google_map_ajax.php',
      success: function(data) {
        var settings = JSON.parse(data);
        var mapSettings = settings.mapOptions;
        var markers = settings.markers;
        var multiLocations = settings.multiLocations;
        var initialZoom = parseInt(settings.initialZoom);

        var map;

        var mapOptions = {};

        if (mapSettings !== undefined) {

          if (mapSettings.mapTypeId !== undefined) {
            mapOptions.mapTypeId = mapSettings.mapTypeId;
          }

          if (mapSettings.scrollWheel !== undefined) {
            mapOptions.scrollwheel = mapSettings.scrollWheel;
          }

          if (mapSettings.draggable !== undefined) {
            mapOptions.draggable = mapSettings.draggable;
          }

          if (mapSettings.disableDoubleClickZoom !== undefined) {
            mapOptions.disableDoubleClickZoom = mapSettings.disableDoubleClickZoom;
          }

          if (mapSettings.zoomControl !== undefined) {
            mapOptions.zoomControl = mapSettings.zoomControl;

            if (mapSettings.zoomPosition !== undefined) {
              mapOptions.zoomControlOptions = {};
              mapOptions.zoomControlOptions.position = google.maps.ControlPosition[mapSettings.zoomPosition];
            }
          }

          if (mapSettings.streetViewControl !== undefined) {
            mapOptions.streetViewControl = mapSettings.streetViewControl;

            if (mapSettings.streetViewPosition !== undefined) {
              mapOptions.streetViewControlOptions = {};
              mapOptions.streetViewControlOptions.position = google.maps.ControlPosition[mapSettings.streetViewPosition];
            }
          }

          if (mapSettings.mapTypeControl !== undefined) {
            mapOptions.mapTypeControl = mapSettings.mapTypeControl;
            mapOptions.mapTypeControlOptions = {};
            mapOptions.mapTypeControlOptions.style = google.maps.MapTypeControlStyle[mapSettings.mapTypeControlStyle];

            if (mapSettings.mapTypePosition !== undefined) {
              mapOptions.mapTypeControlOptions.position = google.maps.ControlPosition[mapSettings.mapTypePosition];
            }
          }

          if (mapSettings.fullScreenControl !== undefined) {
            mapOptions.fullscreenControl = mapSettings.fullScreenControl;

            if (mapSettings.fullscreenPosition !== undefined) {
              mapOptions.fullscreenControlOptions = {};
              mapOptions.fullscreenControlOptions.position = google.maps.ControlPosition[mapSettings.fullscreenPosition];
            }
          }

          if (mapSettings.scaleControl !== undefined) {
            mapOptions.scaleControl = mapSettings.scaleControl;

            if (mapSettings.scalePosition !== undefined) {
              mapOptions.scaleControlOptions = {};
              mapOptions.scaleControlOptions.position = google.maps.ControlPosition[mapSettings.scalePosition];
            }
          }

          if (mapSettings.rotateControl !== undefined) {
            mapOptions.rotateControl = mapSettings.rotateControl;

            if (mapSettings.rotatePosition !== undefined) {
              mapOptions.rotateControlOptions = {};
              mapOptions.rotateControlOptions.position = google.maps.ControlPosition[mapSettings.rotatePosition];
            }
          }

          if (mapSettings.mapStyles !== undefined) {
            mapOptions.styles = JSON.parse(mapSettings.mapStyles);
          }
        }

        
        var bounds;
        if (!multiLocations && markers.length > 0) {
          mapOptions.zoom = initialZoom;
        } else if (multiLocations && markers.length > 0) {
          bounds = new google.maps.LatLngBounds();
        }

        map = new google.maps.Map(document.getElementById('google-map'), mapOptions);

        var geocoder = new google.maps.Geocoder();

        var address;
        var title;
        var markerOptions;

        var setGeocode = function(markers, markerOptions, bounds) {
          geocoder.geocode( { 'address': markerOptions.address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {

              if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
                // map.setCenter(results[0].geometry.location);
                markerOptions.position = results[0].geometry.location;

                var infowindow = new google.maps.InfoWindow(
                    { content: '<b>'+markerOptions.title+'</b>',
                      size: new google.maps.Size(150,50)
                    });

                if (multiLocations && markers.length > 0) {
                  bounds.extend(markerOptions.position);
                }

                var marker = new google.maps.Marker(markerOptions);
                google.maps.event.addListener(marker, 'click', function() {
                    infowindow.open(map,marker);
                });

                if (!multiLocations) {
                  map.setCenter(markerOptions.position);
                } else if (multiLocations && markers.length > 0) {
                  map.fitBounds(bounds);
                }

              } else {
                alert("No results found");
              }
            } else {
              alert("Geocode was not successful for the following reason: " + status);
            }
          });
        };

        for (var i = 0; i < markers.length; i++) {
          markerOptions = {
            address: markers[i].address,
            title: markers[i].title,
            map: map,
          };

          if (markers[i].dropAnimation === true) {
            markerOptions.animation = google.maps.Animation.DROP;
          }

          if (markers[i].customIcon !== false ) {
            markerOptions.icon = markers[i].customIcon;
          }

          if (geocoder) {
            setGeocode(markers, markerOptions, bounds);
          }
        }
      }
    });
    // initMap();
  }
});