var mainNav = $('nav.main-nav');
var mainMenu = $('ul#menu-primary-navigation');
var menuBars = $('button.menu-bars-wrapper');
var topLevelMenu = mainMenu.children('li.menu-item');
var topLevelMenuTotal = topLevelMenu.length;
var topLevelMenuHalf = Math.ceil(topLevelMenuTotal/2);
var companyLogo = $('a.company-logo-link').clone();
var topLevelMenuCount = 0;
var menuParents = mainMenu.find('li.menu-item-has-children');
var parentLink = menuParents.children('a');
var setMenuArrow = $('<i class="menu-arrow fa fa-chevron-right"></i>');
var mobileState;

if (window.innerWidth < 768) {
  mobileState = true;
} else {
  mobileState = false;
}

var companyPhone = mainNav.data('phone');
var extraLinks = $('<li class="menu-item extra-links"><a class="home-link col-3 col-md-2" href="/"><i class="fa fa-home"></i></a><a class="nav-phone col-9 col-md-2 col-md-offset-7 col-lg-3" href="tel:'+companyPhone+'">'+companyPhone+'</a></li>');
mainMenu.append(extraLinks);

var companyLogoLink = $('<li class="menu-item hide show-inline-block-md home-item"></li>');
companyLogoLink.append(companyLogo);

topLevelMenu.each(function(){
  if (topLevelMenuCount < topLevelMenuHalf) {
    $(this).after(companyLogoLink);
  }
  topLevelMenuCount++;
});

parentLink.append(setMenuArrow);

function menuToggle(funcThis, type, e) {
  var target = $(e.target);
  var menuItem = funcThis;
  var menuArrow = menuItem.children('a').children('.menu-arrow');

  if (!menuItem.hasClass('targeted') && (type === 'click' || type === 'hover-in')) {
    var otherOpenItems = menuItem.siblings('.targeted');
    var otherMenuArrows = otherOpenItems.find('.fa-chevron-up');
    otherMenuArrows.toggleClass('fa-chevron-up fa-chevron-right');
    otherOpenItems.find('.sub-menu').stop(true, true).slideUp(function(){
      otherOpenItems.find('.sub-menu').attr('style', '');
    });
    otherOpenItems.removeClass('targeted');
    otherOpenItems.find('.targeted').removeClass('targeted');
    menuItem.addClass('targeted');
    menuItem.children('.sub-menu').stop(true, true).slideDown();
    menuArrow.toggleClass('fa-chevron-right fa-chevron-up');
    
  } else if (type === 'click' || type === 'hover-out') {
    if (target.hasClass('menu-arrow') || type === 'hover-out') {
      menuItem.children('.sub-menu').slideUp();
      menuItem.removeClass('targeted');

      if (menuArrow.hasClass('fa-chevron-up')) {
        menuArrow.toggleClass('fa-chevron-right fa-chevron-up');
      }
    } else if (target.is('a') && type === 'click') {
      var itemHref = target.attr('href');

      if (itemHref.match('^/wp-content/uploads')) {
        window.open(itemHref, '_blank');
      } else {
        window.location.replace(itemHref);
      }
    }
  }
}

menuBars.on('click', function(){
  if ($(this).children().hasClass('fa-times')) {
    var openItems = mainMenu.find('.targeted');
    openItems.find('i.fa-chevron-up').toggleClass('fa-chevron-up fa-chevron-right');
    openItems.find('.sub-menu').slideUp();
    openItems.removeClass('targeted');
  }
  $(this).children('.fa').toggleClass('fa-bars fa-times');

  mainMenu.slideToggle();
});

$(window).on('resize', function(){
  if (window.innerWidth < 768 && !mobileState) {
    mobileState = true;
    mainMenu.find('.sub-menu').attr('style', '');
    mainMenu.find('.targeted').removeClass('targeted');
    mainMenu.find('i.fa-chevron-up').toggleClass('fa-chevron-up fa-chevron-right');

  } else if (window.innerWidth >= 768 && mobileState) {
    mobileState = false;

    if (menuBars.children('.fa').hasClass('fa-times')) {
      menuBars.children('.fa-times').toggleClass('fa-times fa-bars');
      mainMenu.find('.sub-menu').attr('style', '');
      mainMenu.find('.targeted').removeClass('targeted');
      mainMenu.find('i.fa-chevron-up').toggleClass('fa-chevron-up fa-chevron-right');
    }
    mainMenu.attr('style', '');
  }
});

$('li.menu-item-has-children').on('click', function(e) {
  e.stopPropagation();
  e.preventDefault();
  menuToggle($(this), 'click', e);
});

var showMenuTimer;
$('li.menu-item-has-children').hover(function(e){
  if (window.innerWidth >= breakpoints.md) {
    e.stopPropagation();

    var toggleMenu = function($this, type, event) {
      menuToggle($this, type, event);
    };
    
    showMenuTimer = setTimeout(toggleMenu, 200, $(this), 'hover-in', e);
  }
}, function(e) {
  if (window.innerWidth >= breakpoints.md) {
    clearTimeout(showMenuTimer);
    menuToggle($(this), 'hover-out', e);
  }
});