var riverhounds = riverhounds || {};

var videosArea = $('#videos_area');
var ytApiKey = videosArea.data('api');
var ytChannel = videosArea.data('channel');

riverhounds.newsController = {
  _currentID: null,
  _reqURL: 'https://www.googleapis.com/youtube/v3/playlistItems?playlistId='+ytChannel+'&key='+ytApiKey+'&fields=items&part=snippet&type=video&maxResults=12',
    
  init: function() {
    this._bindListeners();
        
    var self = this;
    $.get(this._reqURL, function(data) {
      var template = $("#tpl_video_list").html();
      var compiled = Mustache.to_html(template, data);
      var firstID = data.items[0].snippet.resourceId.videoId;
      self._currentID = firstID;
      $('#videos_area').html(compiled);
      self._makeChosenLinkBold();
      
      $('.media-object').matchHeight();
    });
  },
    
  _setVideo: function(id) {
    if(id == this._currentID) return;
    
    this._currentID = id;
    $('#the_video').attr('src', 'https://www.youtube.com/embed/'+id);
    this._makeChosenLinkBold();
  },
    
  _makeChosenLinkBold: function() {
    $('.setvideo').removeClass('active');
    $('[data-id="'+ this._currentID +'"]').addClass('active');
  },
    
  _bindListeners: function() {
    var self = this;
    $(document).on('click', '.setvideo', function(e) {
      e.preventDefault();
      var id = $(this).data('id');
      self._setVideo(id);
    });
  }
};

if ($('#videos_area').length !== 0) {
  riverhounds.newsController.init();
}