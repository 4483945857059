var eventDay = getQuery.eventday;
var forcedDay = false;

if (eventDay) {
  forcedDay = true;
}

$('.tribe-events-othermonth').children().remove();
$('#tribe-mobile-container').remove();

var monthsEvents = $('tbody.days-grid').find('.day-with-events');
var featuredEvents = $('tbody.days-grid').find('.day-with-events.tribe-event-featured');
var featuredData = '';
var registerLink;
var border = monthsEvents.first().data('border');

if (forcedDay) {
  var targetDay = $('tbody.days-grid').find('td.tribe-event-day-'+eventDay+' .day-with-events');
  featuredData = targetDay.data('eventjson');
  registerLink = targetDay.data('register');
  targetDay.children('.num-background').toggleClass('no-fa fa-circle');
  targetDay.children('.circle').toggleClass('on-circle');
  targetDay.addClass('selected-event');
} else if (monthsEvents.length === 1 ) {
  featuredData = monthsEvents.data('eventjson');
  registerLink = monthsEvents.data('register');
  monthsEvents.children('.num-background').toggleClass('no-fa fa-circle');
  monthsEvents.children('.circle').toggleClass('on-circle');
  monthsEvents.addClass('selected-event');
} else if (monthsEvents.length > 1) {
  
  if (featuredEvents.length > 1) {
    featuredData = featuredEvents.first().data('eventjson');
    registerLink = featuredEvents.first().data('register');
    featuredEvents.first().children('.num-background').toggleClass('no-fa fa-circle');
    featuredEvents.first().children('.circle').toggleClass('on-circle');
    featuredEvents.first().addClass('selected-event');
  } else if (featuredEvents.length === 1) {
    featuredData = featuredEvents.data('eventjson');
    registerLink = featuredEvents.data('register');
    featuredEvents.children('.num-background').toggleClass('no-fa fa-circle');
    featuredEvents.children('.circle').toggleClass('on-circle');
    featuredEvents.addClass('selected-event');
  } else {
    featuredData = monthsEvents.first().data('eventjson');
    registerLink = monthsEvents.first().data('register');
    monthsEvents.first().children('.num-background').toggleClass('no-fa fa-circle');
    monthsEvents.first().children('.circle').toggleClass('on-circle');
    monthsEvents.first().addClass('selected-event');
  }
}

function updateInfoBox(eventsData, registerLinks, border) {
  var eventBox = $('.event-info-box');
  var eventHtml = '<div class="events-container">';

  for (var i = 0; i < eventsData.length; i++) {
    var eventData = eventsData[i];
    eventHtml += '<section class="event-section">';
    if (eventData.title) {
      eventHtml += '<h2 class="event-title">'+eventData.title+'</h2>';
    }
    
    if (eventData.dateDisplay) {
      eventHtml += '<div class="event-date-display">'+eventData.dateDisplay+'</div>';
    }
    
    if (eventData.excerpt) {
      eventHtml += '<div class="event-excerpt">'+eventData.excerpt+'</div>';
    }

    if (registerLinks[i] !== 'false') {
      eventHtml += '<div class="button-container"><div class="border-button-wrapper" style="background-image:url('+border+')"><a href="'+registerLinks[i]+'" class="button border-button">REGISTER HERE</a></div></div>';
    }
    eventHtml += '</section>';
  }

  if (eventsData.length === 0) {
    eventHtml += '<section class="event-section">';
    eventHtml += '<h2 class="event-title">No Events</h2>';
    eventHtml += '<div class="event-excerpt">There are currently no events for this month. Please, check back soon!</div>';
    eventHtml += '</section>';
  }

  eventHtml += '</div>';

  eventBox.html(eventHtml);
}

updateInfoBox(featuredData, registerLink, border);

$('.day-with-events').on('click', function(){
  var selectedEvent = $(this);

  if (!selectedEvent.hasClass('selected-event')) {
    var eventData = selectedEvent.data('eventjson');
    var registerLink = selectedEvent.data('register');
    var currentlySelected = selectedEvent.parents('tbody.days-grid').find('.selected-event');
    currentlySelected.removeClass('selected-event');
    selectedEvent.addClass('selected-event');
    currentlySelected.children('.num-background').toggleClass('fa-circle no-fa');
    currentlySelected.children('.circle').toggleClass('on-circle');
    selectedEvent.children('.num-background').toggleClass('fa-circle no-fa');
    selectedEvent.children('.circle').toggleClass('on-circle');
    updateInfoBox(eventData, registerLink, border);
  }
});