/***************
 * BREAKPOINTS *
 ***************/

var breakpoints = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1366
};

/******************
 * AJAX DIRECTORY *
 ******************/

var ajaxDir = '/wp-content/themes/basetheme/ajax/';

/***************
 * MEDIA COVER *
 ***************/
/*
** These functions are to have have media behave like background-size: cover
*/

function adjustDimensions(item, minW, minH, maxW, maxH) {
  item.css({
  minWidth: minW,
  minHeight: minH,
  maxWidth: maxW,
  maxHeight: maxH
  });
} // end function adjustDimensions

function mediaCoverBounds() {
  var mediaCover = $('.media-cover');

  mediaCover.each(function() {
    var image = $(this);
    adjustDimensions(image, '', '', '', '');

    if (image.hasClass('hide')) {
      image.removeClass('hide');

      var background = image.parent().css('background');

      if (typeof background !== typeof undefined && background !== false) {
        image.parent().css('background', '');
      }
    }

    var mediaWrapper = image.parents('.media-cover-wrapper');
    var mediaWrapperWidth = mediaWrapper.outerWidth();
    var mediaWrapperHeight = mediaWrapper.outerHeight();
    var mediaCoverWidth = image.outerWidth();
    var mediaCoverHeight = image.outerHeight();
    var maxCoverWidth;
    var maxCoverHeight;

    if (mediaCoverWidth > mediaWrapperWidth && mediaCoverHeight > mediaWrapperHeight) {

      if (mediaWrapperHeight/mediaWrapperWidth > mediaCoverHeight/mediaCoverWidth) {
        maxCoverWidth = '';
        maxCoverHeight = '100%';
      } else {
        maxCoverWidth = '100%';
        maxCoverHeight = '';
      } // end if

      adjustDimensions(image, '', '', maxCoverWidth, maxCoverHeight);
    } else {
      adjustDimensions(image, '100%', '100%', '', '');
    } // end if
  }); // end mediaCover.each
} // end function mediaCoverBounds

$(window).on('load', function(){
  mediaCoverBounds();
});

$(window).on('resize', function(){
  mediaCoverBounds();
});

// Get Url Vars

function getUrlVars() {
  var vars = [], hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for(var i = 0; i < hashes.length; i++)
  {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
  }
  return vars;
}

var getQuery = getUrlVars();