$('.chart-accordion-title-wrapper').on('click', function(){
  $(this).find('i.fa').toggleClass('fa-chevron-down fa-chevron-up');
  $(this).siblings('.accordion-info-container').slideToggle();
});

var pageMainWidth = $('#page-main').innerWidth();

$('table.chart-desktop').each(function() {
  var chartTable = $(this);
  var chartWidth = chartTable.outerWidth();
  var chartAccordion = chartTable.siblings('.chart-mobile');

  if (chartWidth > pageMainWidth) {
    chartTable.removeClass('show-table').addClass('hide');
    chartAccordion.removeClass('hide').addClass('show-block');
  }

  $(window).on('resize', function(){
    var newPageWidth = $('#page-main').innerWidth();
    chartTable.removeClass('hide').addClass('show-table');
    chartAccordion.removeClass('show-block').addClass('hide');
    chartWidth = chartTable.outerWidth();

    if (chartWidth > newPageWidth) {
      chartTable.removeClass('show-table').addClass('hide');
      chartAccordion.removeClass('hide').addClass('show-block');
    }
  });
});